.modal-dialog-container .modal-content {
  background-color: var(--lb-white-color);
  border-radius: 25px !important;
  padding: 50px;
}

.modal-dialog-container .modal-header {
  border: none;
}

.modal-dialog-container .modal-body {
  min-height: 200px;
}

.modal-dialog-container .modal-footer {
  border: none;
  display: block;
}
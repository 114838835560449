@import "./color.css";
@import "./cursor.css";
@import "./navbar.css";
@import "./subnav-panel.css";
@import "./form.css";
@import "./button.css";
@import "./breadcrumb.css";
@import "./card.css";
@import "./list.css";
@import "./table.css";
@import "./badge.css";
@import "./text.css";
@import "./pagination.css";
@import "./pages/style.css";

/*******************
Global Styles
*******************/
* {
  outline: none;
}

html {
  position: relative;
  min-height: 100%;
  background: #f2f7f8;
  font-family: 'Roboto', sans-serif;
}

body {
  background: #f2f7f8;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #54667a;
  font-weight: 300;
}

a:hover, a:focus {
  text-decoration: none;
}

a.link {
  color: #54667a;
}

a.link:hover, a.link:focus {
  color: #009efb;
}


/*******************
Headings
*******************/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2c2b2e;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

h1 {
  line-height: 48px;
  font-size: 36px;
}

h2 {
  line-height: 36px;
  font-size: 24px;
}

h3 {
  line-height: 30px;
  font-size: 21px;
}

h4 {
  line-height: 22px;
  font-size: 18px;
}

h5 {
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
}

h6 {
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
}

html body .dl {
  display: inline-block;
}

html body .db {
  display: block;
}

.no-wrap td, .no-wrap th {
  white-space: nowrap;
}

/*******************
Blockquote
*******************/
blockquote {
  border: 1px solid rgba(120, 130, 140, 0.13);
  border-left: 5px solid #009efb;
  padding: 15px;
}

.clear {
  clear: both;
}

ol li {
  margin: 5px 0;
}


/*******************
Content wrapper
*******************/

.content-wrapper {
  padding-top: 70px;
  padding-bottom: 50px;
}

.content {
  padding: 30px 15px;
}

.vh-100 {
  height: calc(100vh - 70px) !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}


/*******************
/*General Elements
*******************/
a {
  color: #55ce63;
}

a.link:hover, a.link:focus {
  color: #55ce63 !important;
}

.react-viewer-close.react-viewer-btn {
  z-index: 99999 !important;
  top: 100px;
  right: 100px;
  background: none;
}

.site-tree-search-value {
  color: #009efb;
  font-weight: 400;
}